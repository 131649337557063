import React from "react";
import Layout from "../components/organisms/layout/layout";
import SEO from "../shared/seo";
import "./../styles/index.scss";
import { ReferenciaContenido } from "../interfaces/ReferenciaContenido";
import LibraryComponents from "../components/templates/library-components/libraryComponents";

const IndexPage = (props: any | undefined) => {
   const referenciaContenido: ReferenciaContenido[] =
      props?.pageContext.allContentfulHome.nodes[0].referenciaContenido;
   const metaTitle: string = props?.pageContext.allContentfulHome.nodes[0].metaTitulo;
   const metaDescription: any = props?.pageContext.allContentfulHome.nodes[0].metaDescripcion;

   return (
      <>
         <Layout hideHeaderDesktop={false} hideHeaderMobile={true}>
            <SEO
               title={
                  metaTitle ||
                  "Caja de compensación familiar de Antioquia - Comfama"
               }
               description={
                  metaDescription ?
                  metaDescription.json.content[0].content[0].value :
                  "En Comfama, Caja de Compensación Familiar de Antioquia, trabajamos por una clase media antioqueña consciente, productiva, libre y feliz."
               }
               lang={"es"}
               fbId={process.env.FACEBOOK_APP_ID_HOME}
            />
            {referenciaContenido.map(
               (value: ReferenciaContenido, index: number) => (
                  <section id={"section" + index} key={`section-${index}`}>
                     <LibraryComponents content={value} categoryName={"Home"} />
                  </section>
               )
            )}
         </Layout>
      </>
   );
};

export default IndexPage;
